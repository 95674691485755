import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import TagListing from "../components/Language/TagListing";

export default function GettingHelpTemplate({ pageContext, data }) {
  const { gettingHelp } = pageContext;
  const languageEdges = data.allLanguagesJson.edges;
  return (
    <Layout>
      <Helmet
        title={`Languages with "${gettingHelp}" getting help | ${config.siteTitle}`}
      />
      <TagListing
        tag={gettingHelp}
        category="Getting Help"
        allLanguagesJson={data.allLanguagesJson}
        languageEdges={languageEdges}
        description="Languages with robust, well-organized documentation make it much easier to find the answers you need, or at least clues. Open source projects also invite more eyes and collaborators, which makes it easier to get help when you get stuck."
      />
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query GettingHelpPage($gettingHelp: String) {
    allLanguagesJson(
      limit: 1000
      filter: { getting_help: { value: { eq: $gettingHelp } } }
    ) {
      totalCount
      edges {
        node {
          slug
          name
          description
          used_for
          getting_help {
            value
          }
          pay {
            value
          }
          barrier_to_entry {
            value
          }
          demand {
            value
          }
        }
      }
    }
  }
`;
